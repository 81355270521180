<template>
    <div class="page-wrapper">
        <section class="hero">
            <div class="content">
                <h1 class="medium-title" data-inview="fadeInUp" data-delay="200">
                    {{ contentIsLoaded ? content.entete.surtitre : '' }}
                </h1>
                <h2 class="title" data-inview="fadeInUp" data-delay="300">
                    {{ contentIsLoaded ? content.entete.titre : '' }} <span>+</span>
                </h2>
                <div class="wrap" data-inview="fadeInUp" data-delay="400">
                    <svg
                        class="plus"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        v-if="contentIsLoaded && content.entete.titre2"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0 19.8412V15.1146C0 13.9109 0.981003 12.9299 2.22896 12.9299H12.9299V2.22896C12.9299 0.980966 13.9109 0 15.1146 0H19.8412C21.0892 0 22.0701 0.981003 22.0701 2.22896V12.9299H32.771C33.9748 12.9299 35 13.9109 35 15.1146V19.8412C35 21.0892 33.9748 22.0701 32.771 22.0701H22.0701V32.771C22.0701 33.9748 21.0891 35 19.8412 35H15.1146C13.9109 35 12.9299 33.9748 12.9299 32.771V22.0701H2.22896C0.980966 22.0701 0 21.0891 0 19.8412V19.8412Z"
                        />
                    </svg>
                    <h2 class="title">{{ contentIsLoaded ? content.entete.titre2 : '' }}</h2>
                </div>
                <p role="heading" aria-level="2" class="small-title" data-inview="fadeInUp" data-delay="500">
                    {{ contentIsLoaded ? content.entete.date : '' }}
                </p>
            </div>
            <a v-scroll-to:-80 href="#expo" :aria-label="$t('Défiler la page à la section expo')">
                <svg
                    class="arrow-down"
                    viewBox="0 0 32 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.3336 2.22222C13.3336 0.994418 14.328 -1.50805e-07 15.5559 -9.71364e-08C16.7837 -4.34674e-08 17.7781 0.994418 17.7781 2.22222L17.7781 37.7778C17.7781 39.0056 16.7837 40 15.5559 40C14.328 40 13.3336 39.0056 13.3336 37.7778L13.3336 2.22222Z"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M27.3167 22.8723C28.1862 22.0056 29.5917 22.0056 30.4612 22.8723C31.3279 23.7418 31.3279 25.1473 30.4612 26.0168L17.1278 39.3501C16.2584 40.2168 14.8529 40.2168 13.9834 39.3501L0.650051 26.0168C-0.216652 25.1473 -0.216652 23.7418 0.650051 22.8723C1.51953 22.0056 2.92504 22.0056 3.79451 22.8723L15.5556 34.6362L27.3167 22.8723Z"
                    />
                </svg>
            </a>
            <video
                v-if="pageIsLoaded && content.entete.video"
                class="video"
                :poster="content.entete.image.url"
                :src="content.entete.video.url"
                playsinline
                autoplay
                muted
                loop
            ></video>
            <video
                v-if="pageIsLoaded && content.entete.video"
                class="video-mobile"
                :poster="content.entete.imageMobile.url"
                :src="content.entete.videoMobile.url"
                playsinline
                autoplay
                muted
                loop
            ></video>
        </section>

        <section class="partenaires" v-if="pageIsLoaded && content.partenaires && content.partenaires.activerLeModulePartenaires">
            <div class="left">
                <p class="partenaire-label text" v-if="content.partenaires.titrePartenairesGauche">{{content.partenaires.titrePartenairesGauche}}</p>
                <img :src="content.partenaires.logoPartenaireGauche.url" :alt="content.partenaires.logoPartenaireGauche.titre" v-if="content.partenaires.logoPartenaireGauche.url">
            </div>
            <div class="right">
                <p class="partenaire-label text" v-if="content.partenaires.titrePartenaireDroite">{{content.partenaires.titrePartenaireDroite}}</p>
                <img :src="content.partenaires.logoPartenaireDroite.url" :alt="content.partenaires.logoPartenaireDroite.titre" v-if="content.partenaires.logoPartenaireDroite.url">
            </div>
        </section>

        <div id="expo"></div>

        <div v-if="pageIsLoaded">
            <div v-for="(block, i) in content.contenuDeLaPage" :key="i">
                <div v-if="block.type === 'imageInformations'">
                    <section class="mobile-title" v-if="block.titreSeparateur">
                        <h3 class="title smaller" v-html="block.titreSeparateur"></h3>
                    </section>

                    <section class="split" :class="block.direction ? 'right' : 'left'">

                        <div v-if="i === 0" class="img expo-anims" data-inview="revealRight" data-delay="200">
                            <img
                                v-if="$route.meta.lang === 'fr'"
                                class="logo"
                                src="@/assets/img/imagine-fr.svg"
                                alt="Logo Imagine"
                            />
                            <img
                                v-if="$route.meta.lang === 'en'"
                                class="logo"
                                src="@/assets/img/imagine-en.svg"
                                alt="Logo Imagine"
                            />

                            <img
                                class="feuille-left"
                                src="@/assets/img/expo-plant-left.png"
                                alt="Jungle feuille"
                            />
                            <img
                                class="feuille-right"
                                src="@/assets/img/expo-plant-right.png"
                                alt="Jungle feuille"
                            />
                            <div class="affiche-wrap">
                                <img
                                    class="affiche"
                                    v-if="$route.meta.lang === 'fr'"
                                    src="@/assets/img/expo-affiche-2.jpg"
                                    alt="Affiche Dinosaures autour du monde"
                                />
                                <img
                                    class="affiche"
                                    v-if="$route.meta.lang === 'en'"
                                    src="@/assets/img/expo-affiche-en-2.jpg"
                                    alt="Affiche Dinosaures autour du monde"
                                />
                            </div>
                            <img
                                class="background"
                                src="@/assets/img/expo-background.jpg"
                                alt="Dinosaures autour du monde"
                            />
                        </div>

                        <div v-else-if="i === 1" class="img dino-anims" data-inview="revealLeft" data-delay="200">
                            <img
                                v-if="$route.meta.lang === 'fr'"
                                class="logo"
                                src="@/assets/img/dino-logo-fr.png"
                                alt="Dinosaures de l’Antarctique 3D"
                            />
                            <img
                                v-if="$route.meta.lang === 'en'"
                                class="logo"
                                src="@/assets/img/dino-logo-en.png"
                                alt="Dinosaures de l’Antarctique 3D"
                            />
                            <img
                                class="credits"
                                src="@/assets/img/credits.png"
                                alt="Dinosaures de l’Antarctique 3D"
                            />
                            <img
                                class="bg"
                                src="@/assets/img/film-background.jpg"
                                alt="Exposition Background"
                            />
                            <img class="left" src="@/assets/img/film-layer-left.png" alt="Exposition Forêt" />
                            <img class="dino" src="@/assets/img/film-layer-dino.png" alt="Exposition Dino" />
                            <img
                                class="bottom"
                                src="@/assets/img/film-layer-bottom.png"
                                alt="Exposition Herbes"
                            />
                        </div>

                        <div v-else class="img expo-anims" :data-inview="block.direction ? 'revealLeft' : 'revealRight'" data-delay="200">
                            <img
                                class="image"
                                :src="block.image.url"
                                :alt="block.image.title"
                            />
                        </div>

                        <div class="data" data-inview="slideLeft" data-delay="300">
                            <h2 v-if="block.surtitre" class="small-title">{{ block.surtitre }}</h2>
                            <h3 v-if="block.titre" class="title" v-html="block.titre"></h3>
                            <p class="text">{{ block.texte }}</p>
                            <ul>
                                <li v-for="(item, i) in block.liste" :key="i" class="text">
                                    <span v-html="item.texte"></span>
                                </li>
                            </ul>
                            <a v-if="block.bandeAnnonce" :href="block.bandeAnnonce" class="play-button glightbox" :aria-label="$t('Ouvrir la vidéo')">
                                <p class="text" v-if="block.titreBandeAnnonce">{{ block.titreBandeAnnonce }}</p>
                                <p class="text" v-else>{{ $t('film-trailer') }}</p>
                                <svg
                                    class="play"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M15 0C6.72663 0 0 6.72663 0 15C0 23.2734 6.72663 30 15 30C23.2734 30 30 23.2734 30 15C30 6.72663 23.2734 0 15 0ZM19.8319 15.7573L12.7576 20.4632C12.1581 20.8739 11.3365 20.4311 11.3365 19.7058V10.2949C11.3365 9.56836 12.1581 9.12676 12.7576 9.53754L19.8306 14.2422C20.3685 14.5888 20.3685 15.4103 19.8319 15.757L19.8319 15.7573Z"
                                    />
                                </svg>
                            </a>
                            <p class="text" v-if="block.informations">{{ block.informations }}</p>
                            <div class="wrap-cta">
                                <a :href="content.lienBilleterie" target="_blank" class="cta" :aria-label="$t('Achetez vos billets')">
                                    <p class="text">
                                        {{ content.titreBilleterieCourt
                                        }}<span> {{ content.titreBilleterie }}</span>
                                    </p>
                                    <svg
                                        class="arrow"
                                        viewBox="0 0 25 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M1.38889 11.111C0.621511 11.111 0 10.4895 0 9.72211C0 8.95473 0.621511 8.33322 1.38889 8.33322H23.6111C24.3785 8.33322 25 8.95473 25 9.72211C25 10.4895 24.3785 11.111 23.6111 11.111H1.38889Z"
                                        />
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M14.2952 2.37155C13.7535 1.82813 13.7535 0.949689 14.2952 0.406267C14.8386 -0.135422 15.717 -0.135422 16.2605 0.406267L24.5938 8.7396C25.1355 9.28302 25.1355 10.1615 24.5938 10.7049L16.2605 19.0382C15.717 19.5799 14.8386 19.5799 14.2952 19.0382C13.7535 18.4948 13.7535 17.6164 14.2952 17.0729L21.6476 9.72227L14.2952 2.37155Z"
                                        />
                                    </svg>
                                </a>
                                <img
                                    v-if="block.logo"
                                    class="logo"
                                    :src="block.logoG.url"
                                    :alt="block.logoG.titre"
                                />
                            </div>
                        </div>
                    </section>
                </div>

                <div v-if="block.type === 'temoignages'">
                    <section class="temoignages-wrap" data-inview="fadeInUp" data-delay="200">
                        <div class="temoignages-slider" data-init-slider>
                            <div class="temoignage-cell" v-for="(item, i) in block.temoignages" :key="i">
                                <p class="temoignage-text" role="textbox">
                                     «&nbsp;<span v-html="item.texte"></span>&nbsp;»
                                </p>
                                <p class="author">
                                    <strong class="name">{{ item.auteur }}</strong>
                                    <span class="text">{{ item.poste }}</span>
                                </p>
                            </div>
                        </div>
                        <div class="slider-controls">
                            <svg class="arrow-left" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 0C25.3048 0 30.392 2.1078 34.142 5.858C37.892 9.6082 40 14.6956 40 20C40 25.3044 37.8922 30.392 34.142 34.142C30.3918 37.892 25.3044 40 20 40C14.6956 40 9.608 37.8922 5.858 34.142C2.108 30.3918 0 25.3044 0 20C0 14.6956 2.1078 9.608 5.858 5.858C9.6082 2.108 14.6956 0 20 0V0ZM10.2124 20.5L16.5328 26.8204H16.5312C16.6656 26.9548 16.8469 27.0313 17.0359 27.0313C17.225 27.0313 17.4062 26.9548 17.5406 26.8204C17.8125 26.5392 17.8125 26.0923 17.5406 25.8126L12.4282 20.7126H29.2954C29.686 20.7079 30.0001 20.3907 30.0001 20.0001C30.0016 19.8126 29.9282 19.6314 29.797 19.4986C29.6641 19.3657 29.4845 19.2907 29.2954 19.2923H12.4282L17.5406 14.1767C17.7 14.0501 17.7984 13.8595 17.8093 13.6564C17.8203 13.4517 17.7422 13.2533 17.5953 13.1111C17.4484 12.9689 17.2468 12.897 17.0437 12.9142C16.839 12.9314 16.6531 13.0361 16.5312 13.2001L10.2108 19.5205H10.2124C10.0812 19.6502 10.0093 19.8268 10.0093 20.0096C10.0093 20.194 10.0812 20.3705 10.2124 20.5003L10.2124 20.5Z" fill="#2E294E"/>
                            </svg>

                            <svg class="arrow-right" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 0C14.6952 0 9.608 2.1078 5.858 5.858C2.108 9.6082 0 14.6956 0 20C0 25.3044 2.1078 30.392 5.858 34.142C9.6082 37.892 14.6956 40 20 40C25.3044 40 30.392 37.8922 34.142 34.142C37.892 30.3918 40 25.3044 40 20C40 14.6956 37.8922 9.608 34.142 5.858C30.3918 2.108 25.3044 0 20 0V0ZM29.7876 20.5L23.4672 26.8204H23.4688C23.3344 26.9548 23.1531 27.0313 22.9641 27.0313C22.775 27.0313 22.5938 26.9548 22.4594 26.8204C22.1875 26.5392 22.1875 26.0923 22.4594 25.8126L27.5718 20.7126H10.7046C10.314 20.7079 9.99992 20.3907 9.99992 20.0001C9.99836 19.8126 10.0718 19.6314 10.203 19.4986C10.3359 19.3657 10.5155 19.2907 10.7046 19.2923H27.5718L22.4594 14.1767C22.3 14.0501 22.2016 13.8595 22.1907 13.6564C22.1797 13.4517 22.2578 13.2533 22.4047 13.1111C22.5516 12.9689 22.7532 12.897 22.9563 12.9142C23.161 12.9314 23.3469 13.0361 23.4688 13.2001L29.7892 19.5205H29.7876C29.9188 19.6502 29.9907 19.8268 29.9907 20.0096C29.9907 20.194 29.9188 20.3705 29.7876 20.5003L29.7876 20.5Z" fill="#2E294E"/>
                            </svg>

                        </div>
                        <div class="flickity-page-dots pagination">
                            <button v-for="(item, i) in block.temoignages" :key="i" tabindex="-1" class="dot" :class="{ 'is-selected': i == 0 }" :aria-label="'Page dot ' + i" :aria-current="i == 1 ? 'step' : ''"></button>
                        </div>
                    </section>
                </div>

                <div v-if="block.type === 'concours'">
                    <section class="concours-wrap" data-inview="fadeInUp" data-delay="200">
                        <div class="concours">
                            <div class="left">
                                <h2 class="concours-title title" v-if="block.titre">
                                    {{block.titre}}
                                </h2>
                                <h3 class="concours-subtitle small-title" v-if="block.soustitre">
                                    {{block.soustitre}}
                                </h3>
                                <p class="text" v-html="block.texte"></p>
                            </div>
                            <div class="right">
                                <form
                                    :name="block.identificationDuFormulaire"
                                    action=""
                                    method="post"
                                    accept-charset="UTF-8"
                                    @submit="sendForm($event)"
                                    id="form"
                                >
                                    <input type="hidden" name="action" value="contact-form/send" />

                                    <input class="txt" type="email" id="email" name="courriel" v-model="formData.courriel" :placeholder="$t('Entrez votre courriel')" required />

                                    <div class="checkbox-row">
                                        <input type="checkbox" id="promo" name="promo" v-model="formData.promo" value="1" />
                                        <span class="custom-checkbox"></span>
                                        <label for="promo">{{ $t('J’accepte de recevoir les promotions du Centre des sciences') }}</label>
                                    </div>

                                    <div class="checkbox-row">
                                        <input type="checkbox" id="rules" name="rules" v-model="formData.rules" value="1" required />
                                        <span class="custom-checkbox"></span>
                                        <label for="rules">{{ $t('J’accepte les') }} <a :aria-label="$t('Lien externe : Pour les règlements')" :href="block.lienReglements" target="_blank" rel="noopener noreferrer">règlements du concours</a></label>
                                    </div>

                                    <button type="submit" class="cta">
                                        <p class="text">
                                            {{ $t('Participer') }}
                                        </p>
                                        <svg
                                            class="arrow"
                                            viewBox="0 0 25 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M1.38889 11.111C0.621511 11.111 0 10.4895 0 9.72211C0 8.95473 0.621511 8.33322 1.38889 8.33322H23.6111C24.3785 8.33322 25 8.95473 25 9.72211C25 10.4895 24.3785 11.111 23.6111 11.111H1.38889Z"
                                            />
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M14.2952 2.37155C13.7535 1.82813 13.7535 0.949689 14.2952 0.406267C14.8386 -0.135422 15.717 -0.135422 16.2605 0.406267L24.5938 8.7396C25.1355 9.28302 25.1355 10.1615 24.5938 10.7049L16.2605 19.0382C15.717 19.5799 14.8386 19.5799 14.2952 19.0382C13.7535 18.4948 13.7535 17.6164 14.2952 17.0729L21.6476 9.72227L14.2952 2.37155Z"
                                            />
                                        </svg>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>

                <div v-if="block.type === 'carouselDimages'">
                    <section class="carousel-wrap" data-inview="fadeInUp" data-delay="200">
                        <div class="carousel" data-init-slider>
                            <div class="carousel-cell" v-for="(item, i) in block.images" :key="i">
                                <img :src="item.url" :alt="item.title" />
                            </div>
                        </div>
                        <div class="slider-controls">
                            <svg class="arrow-left" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 0C25.3048 0 30.392 2.1078 34.142 5.858C37.892 9.6082 40 14.6956 40 20C40 25.3044 37.8922 30.392 34.142 34.142C30.3918 37.892 25.3044 40 20 40C14.6956 40 9.608 37.8922 5.858 34.142C2.108 30.3918 0 25.3044 0 20C0 14.6956 2.1078 9.608 5.858 5.858C9.6082 2.108 14.6956 0 20 0V0ZM10.2124 20.5L16.5328 26.8204H16.5312C16.6656 26.9548 16.8469 27.0313 17.0359 27.0313C17.225 27.0313 17.4062 26.9548 17.5406 26.8204C17.8125 26.5392 17.8125 26.0923 17.5406 25.8126L12.4282 20.7126H29.2954C29.686 20.7079 30.0001 20.3907 30.0001 20.0001C30.0016 19.8126 29.9282 19.6314 29.797 19.4986C29.6641 19.3657 29.4845 19.2907 29.2954 19.2923H12.4282L17.5406 14.1767C17.7 14.0501 17.7984 13.8595 17.8093 13.6564C17.8203 13.4517 17.7422 13.2533 17.5953 13.1111C17.4484 12.9689 17.2468 12.897 17.0437 12.9142C16.839 12.9314 16.6531 13.0361 16.5312 13.2001L10.2108 19.5205H10.2124C10.0812 19.6502 10.0093 19.8268 10.0093 20.0096C10.0093 20.194 10.0812 20.3705 10.2124 20.5003L10.2124 20.5Z" fill="#2E294E"/>
                            </svg>

                            <svg class="arrow-right" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 0C14.6952 0 9.608 2.1078 5.858 5.858C2.108 9.6082 0 14.6956 0 20C0 25.3044 2.1078 30.392 5.858 34.142C9.6082 37.892 14.6956 40 20 40C25.3044 40 30.392 37.8922 34.142 34.142C37.892 30.3918 40 25.3044 40 20C40 14.6956 37.8922 9.608 34.142 5.858C30.3918 2.108 25.3044 0 20 0V0ZM29.7876 20.5L23.4672 26.8204H23.4688C23.3344 26.9548 23.1531 27.0313 22.9641 27.0313C22.775 27.0313 22.5938 26.9548 22.4594 26.8204C22.1875 26.5392 22.1875 26.0923 22.4594 25.8126L27.5718 20.7126H10.7046C10.314 20.7079 9.99992 20.3907 9.99992 20.0001C9.99836 19.8126 10.0718 19.6314 10.203 19.4986C10.3359 19.3657 10.5155 19.2907 10.7046 19.2923H27.5718L22.4594 14.1767C22.3 14.0501 22.2016 13.8595 22.1907 13.6564C22.1797 13.4517 22.2578 13.2533 22.4047 13.1111C22.5516 12.9689 22.7532 12.897 22.9563 12.9142C23.161 12.9314 23.3469 13.0361 23.4688 13.2001L29.7892 19.5205H29.7876C29.9188 19.6502 29.9907 19.8268 29.9907 20.0096C29.9907 20.194 29.9188 20.3705 29.7876 20.5003L29.7876 20.5Z" fill="#2E294E"/>
                            </svg>
                        </div>
                        <div class="flickity-page-dots pagination">
                            <button v-for="(item, i) in block.images" :key="i" tabindex="-1" class="dot" :class="{ 'is-selected': i == 0 }" :aria-label="'Page dot ' + i" :aria-current="i == 1 ? 'step' : ''"></button>
                        </div>
                    </section>
                </div>
            </div>

            <section class="pricing-wrap" v-if="content.activerForfaits">
                <h3 class="pricing-suptitle" v-if="content.forfaits.surtitreForfaits">
                    {{content.forfaits.surtitreForfaits}}
                </h3>

                <h2 class="pricing-title" v-if="content.forfaits.titre1Forfaits">
                    {{content.forfaits.titre1Forfaits}} <br/>
                    <span>{{content.forfaits.titre2Forfaits}}</span>
                </h2>

                <div class="pricing-grid">
                    <div
                        class="pricing-box"
                        :class="'length-' + content.forfaits.items.length + ' ' +( item.miseEnVedette ? 'focus' : '')"
                        v-for="(item, i) in content.forfaits.items"
                        :key="i"
                    >
                        <h3 class="price-title" v-if="item.titre">{{item.titre}}</h3>
                        <h4 class="price-subtitle" v-if="item.description">{{item.description}}</h4>

                        <div class="pricing-items">
                            <div class="pricing-item" v-for="(tarif, j) in item.tarifs" :key="j">
                                <div class="content">
                                    <span class="label">{{tarif.titre}}</span>
                                    <div class="price" v-if="tarif.prixPromo">
                                        <span class="regular" v-if="tarif.prixReguli">
                                            {{tarif.prixReguli}}
                                        </span>
                                        <span class="promo" v-if="tarif.prixPromo">
                                             {{tarif.prixPromo}}
                                        </span>
                                    </div>
                                    <div class="price" v-else>
                                        <span class="regular alone" v-if="tarif.prixReguli">
                                            {{tarif.prixReguli}}
                                        </span>
                                    </div>
                                </div>
                                <div class="note" v-if="tarif.notes">
                                    {{tarif.notes}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="cta-wrap">
                    <a :aria-label="$t('Lien externe : Achetez vos billets')" :href="contentIsLoaded ? content.lienBilleterie : ''" target="_blank" class="cta">
                    <p class="text">
                        {{ contentIsLoaded ? content.titreBilleterieCourt : ''
                        }}<span> {{ contentIsLoaded ? content.titreBilleterie : '' }}</span>
                    </p>
                    <svg
                        class="arrow"
                        viewBox="0 0 25 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.38889 11.111C0.621511 11.111 0 10.4895 0 9.72211C0 8.95473 0.621511 8.33322 1.38889 8.33322H23.6111C24.3785 8.33322 25 8.95473 25 9.72211C25 10.4895 24.3785 11.111 23.6111 11.111H1.38889Z"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.2952 2.37155C13.7535 1.82813 13.7535 0.949689 14.2952 0.406267C14.8386 -0.135422 15.717 -0.135422 16.2605 0.406267L24.5938 8.7396C25.1355 9.28302 25.1355 10.1615 24.5938 10.7049L16.2605 19.0382C15.717 19.5799 14.8386 19.5799 14.2952 19.0382C13.7535 18.4948 13.7535 17.6164 14.2952 17.0729L21.6476 9.72227L14.2952 2.37155Z"
                        />
                    </svg>
                </a>
                </div>
            </section>

            <section class="partners-logos-wrap" v-if="content.activerLogos">
                <h2 class="partners-logos-title title medium" data-inview="fadeInUp" data-delay="200" v-if="content.titreSimple">{{content.titreSimple}}</h2>

                <div class="partners-logos" data-inview="fadeInUp" data-delay="400">
                    <div class="partners-logos-cell" v-for="(item, i) in content.logos" :key="i">
                        <a v-if="item.lienWeb" :href="item.lienWeb" target="_blank" rel="noopener noreferrer" :aria-label="$t('Lien externe : Site du partenaire')">
                            <img
                                :src="item.url"
                                :alt="item.titre"
                            />
                        </a>
                        <img
                            v-else
                            :src="item.url"
                            :alt="item.titre"
                        />
                    </div>
                </div>
            </section>
        </div>

        <section
            class="pop-up"
            :class="{ active: showpopup && contentIsLoaded && content.popup.activer }"
        >
            <div class="pop">
                <img
                    src="@/assets/img/close.svg"
                    class="close"
                    alt="close icon"
                    @click.prevent="closepopup"
                />
                <p class="title" role="heading" aria-level="4">{{ contentIsLoaded ? content.popup.titre : '' }}</p>
                <p class="text pop-text" v-if="contentIsLoaded && content.popup.texte"><span v-html="contentIsLoaded ? content.popup.texte : ''"></span></p>
                <a
                    :href="contentIsLoaded ? content.popup.lienDuBouton : ''"
                    target="_blank"
                    class="cta"
                    :aria-label="$t('Lien externe : Achetez vos billets')"
                    v-if="contentIsLoaded && content.popup.lienDuBouton"
                >
                    <p class="text">
                        {{ contentIsLoaded ? content.popup.titreDuBouton : '' }}
                    </p>
                    <svg
                        class="arrow"
                        viewBox="0 0 25 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.38889 11.111C0.621511 11.111 0 10.4895 0 9.72211C0 8.95473 0.621511 8.33322 1.38889 8.33322H23.6111C24.3785 8.33322 25 8.95473 25 9.72211C25 10.4895 24.3785 11.111 23.6111 11.111H1.38889Z"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.2952 2.37155C13.7535 1.82813 13.7535 0.949689 14.2952 0.406267C14.8386 -0.135422 15.717 -0.135422 16.2605 0.406267L24.5938 8.7396C25.1355 9.28302 25.1355 10.1615 24.5938 10.7049L16.2605 19.0382C15.717 19.5799 14.8386 19.5799 14.2952 19.0382C13.7535 18.4948 13.7535 17.6164 14.2952 17.0729L21.6476 9.72227L14.2952 2.37155Z"
                        />
                    </svg>
                </a>
            </div>
            <div class="overlay" @click.prevent="closepopup"></div>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { mapState, mapGetters } from 'vuex'
import GLightbox from 'glightbox'

// Flickity
const Flickity = require('flickity')

// Flickity css
require('flickity/dist/flickity.css')

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import initAos from '@/plugins/animations-layer'

gsap.registerPlugin(ScrollTrigger)

// Intro Animation Scripts
require('@/plugins/animations-layer')


import axios from 'axios'
import Swal from 'sweetalert2'

import 'sweetalert2/src/sweetalert2.scss'

export default {
    name: 'Home',

    components: {},

    data() {
        return {
            showpopup: false,
            isSending: false,
            formMessage: '',
            formData: {
                courriel: ''
            },
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    this.lightboxelement = GLightbox


                    setTimeout(() => {
                        this.initGsapUtils()
                        GLightbox()
                        this.initFlickitySliders();
                    }, 1000)

                    initAos()

                    setTimeout(() => {
                        this.showpopup = true
                    }, 10000)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        initFlickitySliders() {
            const flickitySliders = document.querySelectorAll('[data-init-slider]');

            flickitySliders.forEach((slider) => {
                const sliderInstance = new Flickity(slider, {
                    cellAlign: 'center',
                    contain: true,
                    pageDots: false,
                    prevNextButtons: false,
                    wrapAround: true,
                    autoPlay: 4000,
                });

                const prevButton = slider.parentNode.querySelector('.arrow-left');
                const nextButton = slider.parentNode.querySelector('.arrow-right');

                prevButton.addEventListener('click', () => {
                    sliderInstance.previous();
                });

                nextButton.addEventListener('click', () => {
                    sliderInstance.next();
                });

                const dots = slider.parentNode.querySelector('.pagination');

                sliderInstance.on('select', () => {
                    const selectedDot = dots.querySelector('.dot.is-selected');
                    selectedDot.classList.remove('is-selected');

                    const newSelectedDot = dots.children[sliderInstance.selectedIndex];
                    newSelectedDot.classList.add('is-selected');
                });

                setTimeout(() => {
                    sliderInstance.resize();
                }, 1000);
            });
        },
        closepopup() {
            this.showpopup = false
        },
        initGsapUtils() {
            const left = document.querySelector('.dino-anims .left')
            const dino = document.querySelector('.dino-anims .dino')
            const bottom = document.querySelector('.dino-anims .bottom')
            const affiche = document.querySelector('.expo-anims .affiche')
            const feuilleleft = document.querySelector('.expo-anims .feuille-left')
            const feuilleright = document.querySelector('.expo-anims .feuille-right')

            if (left && dino && bottom) {
                gsap.to(left, {
                    scrollTrigger: {
                        trigger: left,
                        start: 'top bottom',
                        end: 'bottom top',
                        scrub: 2,
                    },
                    x: '-20%',
                    ease: 'none',
                })

                gsap.to(bottom, {
                    scrollTrigger: {
                        trigger: bottom,
                        start: 'top bottom',
                        end: 'bottom top',
                        scrub: 2,
                    },
                    x: '20%',
                    ease: 'none',
                })

                gsap.to(dino, {
                    scrollTrigger: {
                        trigger: dino,
                        start: 'top bottom',
                        end: 'bottom top',
                        scrub: 2,
                    },
                    y: '-70%',
                    scale: '2.400',
                    ease: 'none',
                })

                gsap.to(affiche, {
                    scrollTrigger: {
                        trigger: affiche,
                        start: 'top bottom',
                        end: 'bottom top',
                        scrub: 2,
                    },
                    scale: '1.8',
                    ease: 'none',
                })

                gsap.to(feuilleleft, {
                    scrollTrigger: {
                        trigger: feuilleleft,
                        start: 'top bottom',
                        end: 'bottom top',
                        scrub: 2,
                    },
                    x: '-70%',
                    ease: 'none',
                })

                gsap.to(feuilleright, {
                    scrollTrigger: {
                        trigger: feuilleright,
                        start: 'top bottom',
                        end: 'bottom top',
                        scrub: 2,
                    },
                    x: '70%',
                    ease: 'none',
                })
            }
        },
        async sendForm(e) {
            e.preventDefault()

            if (this.isSending) {
                return
            }

            this.isSending = true

            const form = document.querySelector('#form')
            const formId = form.getAttribute('name')

            const formData = new FormData()
            formData.append('formName', formId)
            formData.append('sujet', formId)
            formData.append('courriel', this.formData.courriel)
            formData.append('nom', this.formData.courriel)

            if (this.formData.promo) {
                formData.append('promo', 'oui')
            }

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }

            await axios
                .post(`${process.env.VUE_APP_SITE_BASE_URL}/api/send/contact-form`, formData, config)
                .then(res => {
                    this.formMessage = 'success'
                    this.formMessageStatus = res.status

                    Swal.fire({
                        text: this.$t('Merci pour votre participation.'),
                        icon: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                    this.formData = {
                        courriel: '',
                    }
                    this.formMessage = ''

                    setTimeout(() => {
                        this.isSending = false
                    }, 3000)
                })
                .catch(e => {
                    this.formMessage = e

                    setTimeout(() => {
                        this.formData = {}
                        this.formMessage = ''
                        this.isSending = false
                    }, 2275)
                })
        },
    },
}
</script>
